import React from "react";
import "./Landing.css";
import broom from "./images/broom.png";
import cleaning from "./images/cleaning.png";
import vacuum from "./images/vacuum.png";
import photo2 from "./images/photo_2.jpeg";
import household from "./images/household.png";

const Landing = () => {
  return (
    <div className="wrapper">
      <div className="cta-wrapper">
        <div className="cta-left">
          <div className="phrase">Experience the Sparkle!</div>
          <div className="cta">Book Your Cleaning Service Today.</div>
          <div className="cta-btn">
            dircesalazar@yahoo.com
            <br />
            (541) 901-2213
          </div>
        </div>
        <div className="cta-right">
          <div className="blurb-wrapper">
            <div classname="blurb-title">Introducing Gigi!</div>
            <div className="blurb-image-wrapper">
              <img src={photo2} className="blurb-image" />
            </div>

            <div className="blurb-text">
              With over 15 years of dedicated experience, Gigi is a trusted
              professional in the realm of house and commercial cleaning. Her
              meticulous attention to detail guarantees that every nook and
              cranny receives the utmost care. Trust Gigi to bring a fresh,
              spotless shine to your space, surpassing your expectations.
              Experience the difference of Gigi's unparalleled expertise and let
              her transform your environment into a haven of cleanliness.
            </div>
          </div>
        </div>
      </div>
      <div className="groups">
        <div className="group">
          <div className="wrapper-logo">
            <img src={broom} className="group-logo" />
          </div>
          <div className="group-title">Immaculate Attention to Detail</div>
          Our meticulous cleaning services leave no corner untouched, ensuring a
          spotless and refreshing space.
        </div>
        <div className="group">
          <div className="wrapper-logo">
            <img src={vacuum} className="group-logo" />
          </div>
          <div className="group-title">Time-Saving Expertise</div>
          Delegate the tiresome task of cleaning to a professional, saving your
          valuable time and energy.
        </div>
        <div className="group">
          <div className="wrapper-logo">
            <img src={cleaning} className="group-logo" />
          </div>
          <div className="group-title">Eco-Friendly Practice</div>
          We prioritize both your well-being and the environment, using
          eco-friendly products and practices.
        </div>
        <div className="group">
          <div className="wrapper-logo">
            <img src={household} className="group-logo" />
          </div>
          <div className="group-title">Reliable and Consistent Results</div>
          Trust a reliable and dedicated cleaning professional to consistently
          deliver exceptional results, giving you peace of mind.
        </div>
      </div>
      <div className="services-wrapper">
        <div className="services-tier-title">Services Provided</div>
        <div className="services-tier">
          <div className="services-tier-1">
            <div className="tier-title"> Tier 1</div>
            <div className="tier-list-wrapper">
              <div className="tier-list">
                <ul>
                  <li>Dusting and Wiping Surfaces</li>
                  <li>Vacuuming and Sweeping</li>
                  <li>Basic Bathroom Cleaning</li>
                  <li>Kitchen Cleanup</li>
                  <li>Trash Removal</li>
                </ul>
              </div>
            </div>
            <div className="estimates">FREE ESTIMATES</div>
          </div>
          <div className="services-tier-2">
            <div className="tier-title"> Tier 2</div>
            <div className="tier-list-wrapper">
              <div className="tier-list">
                <ul>
                  <li>Cobweb Removal</li>
                  <li>Baseboard Cleaning</li>
                  <li>Window Cleaning</li>
                  <li>Deep Kitchen Cleaning</li>
                  <li>Deep Bathroom Cleaning</li>
                </ul>
              </div>
            </div>
            <div className="estimates">FREE ESTIMATES</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
